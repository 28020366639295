import React from 'react';

const About = ({ language }) => {
  const content = {
    en: {
      title: 'About Us',
      paragraph1: "Welcome to StudioCeiling! We providing top-notch ceiling design and installation services. Our team of experienced professionals is passionate about transforming your spaces with innovative and beautiful ceiling solutions.",
      paragraph2: "At StudioCeiling, we believe that the ceiling is a crucial element in interior design, often overlooked. Our mission is to bring attention to this important aspect of your home or office, creating stunning and functional designs that elevate your space.",
      paragraph3: "Whether you are looking for a modern, minimalist design or a more traditional, ornate look, we have the expertise and creativity to bring your vision to life. We work closely with our clients to understand their needs and preferences, ensuring a personalized experience and exceptional results.",
      paragraph4: "Thank you for considering StudioCeiling for your ceiling design needs. We look forward to working with you and making your ceilings a masterpiece."
    },
    ka: {
      title: 'ჩვენს შესახებ',
      paragraph1: "კეთილი იყოს თქვენი მობრძანება StudioCeiling-ში! ჩვენ გთავაზობთ მაღალი დონის ჭერის დიზაინს და მონტაჟის მომსახურებას. ჩვენი გამოცდილი პროფესიონალების გუნდი გატაცებულია თქვენი სივრცეების ტრანსფორმაციით ინოვაციური და ლამაზი ჭერის გადაწყვეტილებებით.",
      paragraph2: "StudioCeiling-ში ჩვენ გვჯერა, რომ ჭერი ინტერიერის დიზაინის მნიშვნელოვანი ელემენტია, რომელიც ხშირად იგნორირებულია. ჩვენი მისიაა ყურადღება მივაქციოთ თქვენი სახლის ან ოფისის ამ მნიშვნელოვან ასპექტს, შევქმნათ განსაცვიფრებელი და ფუნქციონალური დიზაინები, რომლებიც ამაღლებს თქვენს სივრცეს.",
      paragraph3: "თქვენ ეძებთ თანამედროვე, მინიმალისტურ დიზაინს ან უფრო ტრადიციულ, ორნამენტულ იერს, ჩვენ გვაქვს ექსპერტიზა და კრეატიულობა, რათა თქვენი ხედვა რეალობად აქციოთ. ჩვენ მჭიდროდ ვთანამშრომლობთ ჩვენს კლიენტებთან მათი საჭიროებებისა და პრეფერენციების გასაგებად, უზრუნველყოფს პერსონალურ გამოცდილებას და განსაკუთრებულ შედეგებს.",
      paragraph4: "გმადლობთ, რომ განიხილავთ StudioCeiling-ს თქვენი ჭერის დიზაინის საჭიროებისთვის. ჩვენ მოუთმენლად ველით თქვენთან მუშაობას და თქვენი ჭერის შედევრად გადაქცევას."
    }
    // Add more languages here if needed...
  };

  return (
    <section id="about" className="p-8 bg-white">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-4">{content[language].title}</h2> 
        <p className="text-lg mb-4">{content[language].paragraph1}</p>
        <p className="text-lg mb-4">{content[language].paragraph2}</p>
        <p className="text-lg mb-4">{content[language].paragraph3}</p>
        <p className="text-lg">{content[language].paragraph4}</p>
      </div>
    </section>
  );
};

export default About;
