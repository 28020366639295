import React from 'react';

const Footer = (
) => {
  // Get current year
const currentYear = new Date().getFullYear();

  return (
    <footer className="flex flex-wrap justify-between items-center p-4 bg-gray-800 text-white">
      <div className="w-full md:w-auto mb-4 md:mb-0">
        <h3 className="text-xl font-bold">StudioCeiling</h3>
        <p>ⓒ {currentYear} </p>
      </div>
      <div className="w-full md:w-auto flex justify-around md:justify-end space-x-4">
        <a href="https://www.facebook.com/CYDceiling?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="hover:underline">
          Facebook
        </a>

        <a href="https://wa.me/995598028028" target="_blank" rel="noopener noreferrer" className="hover:underline">
          WhatsApp
        </a>
      </div>
    </footer>
  );
};

export default Footer;
