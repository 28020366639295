import React from "react";
import Slider from "react-slick";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Main = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="text-center p-8 bg-gray-100 max-w-screen-xl mx-auto">
      <h2 className="text-4xl font-bold mb-4">Welcome to our studio</h2>
      <div className="max-w-md mx-auto"> {/* Constrain slider width */}
        <Slider {...settings} className="image-slider">
          {/* Image Slides */}
          {[image1, image2, image3].map((image, index) => (
            <div key={index} className="w-full">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="w-full h-64 object-contain" 
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Main;
