import React from "react";
import example1 from "../images/example1.jpg";
import example2 from "../images/example2.jpg";
import example3 from "../images/example3.jpg";
import example4 from "../images/example4.jpg";
import example5 from "../images/example5.jpg";
import example6 from "../images/example6.jpg";

const Examples = ({ language }) => {
  const content = {
    en: {
      title: "Examples",
    },
    ka: {
      title: "მაგალითები",
    },
  };

  const examples = [
    { id: 1, src: example1, alt: "Example 1" },
    { id: 2, src: example2, alt: "Example 2" },
    { id: 3, src: example3, alt: "Example 3" },
    { id: 4, src: example4, alt: "Example 4" },
    { id: 5, src: example5, alt: "Example 5" },
    { id: 6, src: example6, alt: "Example 6" },
  ];

  return (
    <section id="examples" className="p-8 bg-gray-100 max-w-screen-xl mx-auto">
      <h2 className="text-3xl font-bold mb-8 text-center">
        {content[language].title}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {examples.map((example) => (
          <div
            key={example.id}
            className="w-full h-64 overflow-hidden rounded-lg shadow-lg bg-cover bg-center relative" // w-full for container width
            style={{ backgroundImage: `url(${example.src})` }}
          >
            {/* Image for accessibility (hidden visually) */}
            <img src={example.src} alt={example.alt} className="sr-only" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Examples;
