import React, { useState } from 'react';

const Header = ({ language, toggleLanguage, toggleTheme }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="flex flex-wrap justify-between items-center p-4 bg-gray-800 text-white">
      <h1 className="text-2xl">StudioCeiling</h1>
      <nav className="flex space-x-4">
        <div className="hidden md:flex space-x-4">
          <a href="#about" className="hover:underline">
            {language === 'en' ? 'About Us' : 'ჩვენს შესახებ'}
          </a>
          <a href="#examples" className="hover:underline">
            {language === 'en' ? 'Examples' : 'მაგალითები'}
          </a>
          <a href="#contact" className="hover:underline">
            {language === 'en' ? 'Contact' : 'კონტაქტი'}
          </a>
          <button onClick={toggleLanguage} className="hover:underline">
            {language === 'en' ? 'Geo/Eng' : 'ინგლისური'}
          </button>
          <button onClick={toggleTheme} className="hover:underline">
            {language === 'en' ? 'Day/Night' : 'დღე/ღამე'}
          </button>
        </div>
        <div className="md:hidden">
          <button onClick={() => setMenuOpen(!menuOpen)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          {menuOpen && (
            <div className="flex flex-col space-y-4 mt-4">
              <a href="#about" className="hover:underline">
                {language === 'en' ? 'About Us' : 'ჩვენს შესახებ'}
              </a>
              <a href="#examples" className="hover:underline">
                {language === 'en' ? 'Examples' : 'მაგალითები'}
              </a>
              <a href="#contact" className="hover:underline">
                {language === 'en' ? 'Contact' : 'კონტაქტი'}
              </a>
              <button onClick={toggleLanguage} className="hover:underline">
                {language === 'en' ? 'Geo/Eng' : 'ქართ/ინგ'}
              </button>
              <button onClick={toggleTheme} className="hover:underline">
                {language === 'en' ? 'Day/Night' : 'დღე/ღამე'}
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
