import React from "react";

const Contact = ({ language, theme }) => {
  const content = {
    en: {
      title: "Contact Us",
      location: "Location",
      streetLabel: "Street:",
      street: "D. Bakradze",
      cityLabel: "City:",
      city: "Tbilisi",
      countryLabel: "Country:",
      country: "Georgia",
      getInTouch: "Get in Touch",
      email: "info@studioceiling.ge",
      phone: "+995 598028028",
      emailLabel: "Email:",
      phoneLabel: "Phone:",
    },
    ka: {
      title: "დაგვიკავშირდით",
      location: "მისამართი",
      streetLabel: "ქუჩა:",
      street: "დ. ბაქრაძე",
      cityLabel: "ქალაქი:",
      city: "თბილისი",
      countryLabel: "ქვეყანა:",
      country: "საქართველო",
      getInTouch: "საკონტაქტო",
      email: "info@studioceiling.ge",
      phone: "+995 598028028",
      emailLabel: "ელ.ფოსტა:",
      phoneLabel: "ტელეფონი:",
    },
  };

  return (
    <section
      id="contact"
      className={`p-8 ${
        theme === "dark" ? "bg-gray-800 text-white" : "bg-gray-200"
      }`}
    >
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">
          {content[language].title}
        </h2>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 pr-4 mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2">
              {content[language].location}
            </h3>
            <p>
              {content[language].streetLabel} {content[language].street}
            </p>
            <p>
              {content[language].cityLabel} {content[language].city}
            </p>
            <p>
              {content[language].countryLabel} {content[language].country}
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-xl font-bold mb-2">
              {content[language].getInTouch}
            </h3>
            <p>
              {content[language].emailLabel}{" "}
              <a
                href={`mailto:${content[language].email}`}
                className={
                  theme === "dark"
                    ? "text-blue-300 hover:underline"
                    : "text-blue-500 hover:underline"
                }
              >
                {content[language].email}
              </a>
            </p>
            <p>
              {content[language].phoneLabel} {content[language].phone}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
