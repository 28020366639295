import React, { useState } from 'react';
import Header from './components/Header'; // Assuming Header.js is in the same directory
import Main from './components/Main'; // Assuming Main.js is in the same directory
import About from './components/About'; // Assuming About.js is in the same directory
import Examples from './components/Examples'; // Assuming Examples.js is in the same directory
import Contact from './components/Contact'; // Assuming Contact.js is in the same directory
import Footer from './components/Footer'; // Assuming Footer.js is in the same directory

const App = () => {
  const [language, setLanguage] = useState('ka');
  const [theme, setTheme] = useState('light');

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'ka' : 'en');
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      <Header language={language} toggleLanguage={toggleLanguage} toggleTheme={toggleTheme} />
      <Main language={language} />
      <About language={language} />
      <Examples language={language} />
      <Contact language={language} />
      <Footer />
    </div>
  );
};

export default App;
